.indiana-scroll-container--hide-scrollbars {
    border-radius: 0; /* or you can use 'unset' */
}

.border-text {
    color: #1d1d40;
    text-shadow:
            -0.5px -0.5px 0 #fcf1a1,
            0.5px -0.5px 0 #fcf1a1,
            -0.5px 0.5px 0 #fcf1a1,
            0.5px 0.5px 0 #fcf1a1;
}
